<template>
  <v-app>
    <v-card flat width="100%" height="100%" color="#f1e6ee" class="lot_login">
      <v-card flat width="480" class="login-wrap">
        <v-card-title class="pa-0 text-center">
          <h1>
            블록체인 기반<br>
            <strong>사내 추첨 시스템</strong>
          </h1>
          <div class="pt-4 d-block h-desc"><h4 class="">초기 비밀번호 변경해주시기 바랍니다.</h4></div>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-container class="pa-0 align-content-center align-center">
            <validation-observer v-slot="{ invalid }" ref="form">
              <form @submit.prevent="submit">
                <validation-provider
                    v-slot="{ errors }"
                    rules="required|min:6|maxByteLength:25"
                    name="ID"
                    :custom-messages="{ required: '기존 비밀번호를 입력하세요', min:'적어도 6글자 이상이어야 합니다.',maxByteLength:'25글자 이하여야 합니다.' }"
                    class="relative"
                >
                  <v-text-field
                      :error-messages="errors"
                      v-model="password"
                      :append-icon="passwordShow ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="passwordShow ? 'text' : 'password'"
                      hint=""
                      label="기존 비밀번호를 입력하세요"
                      name="input-10-1"
                      solo flat outlined
                      @click:append="passwordShow = !passwordShow"
                      class="mt-16 pb-3 pt-0 rounded-sm f14"
                  ></v-text-field>
                </validation-provider>

                <validation-provider
                    v-slot="{ errors }"
                    ref="pw"
                    rules="required|enNumReg"
                    name="Password"
                    :custom-messages="{ required: '비밀번호를 입력하세요.' }"
                    class="relative"
                >
                  <v-text-field
                      :error-messages="errors"
                      v-model="newPassword"
                      :append-icon="newPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="newPasswordShow ? 'text' : 'password'"
                      hint=""
                      label="변경할 비밀번호를 입력"
                      name="input-10-1"
                      solo flat outlined
                      @click:append="newPasswordShow = !newPasswordShow"
                      class="mt-0 pb-0 pt-4 rounded-sm f14"
                  ></v-text-field>
                </validation-provider>

                <validation-provider
                    v-slot="{ errors }"
                    rules="required"
                    name="Password"
                    :custom-messages="{ required: '비밀번호를 입력하세요.' }"
                    class="relative"
                >
                  <v-text-field
                      v-model="checkNewPassword"
                      :error-messages="errors"
                      :append-icon="checkNewPasswordShow ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="checkNewPasswordShow ? 'text' : 'password'"
                      hint="붙어넣기는 할 수 없습니다."
                      label="변경할 비밀번호를 확인"
                      name="input-10-1"
                      solo flat outlined
                      @click:append="checkNewPasswordShow = !checkNewPasswordShow"
                      class="mt-0 pb-0 pt-2 rounded-sm f14"
                      onpaste="return false;"
                  ></v-text-field>
                </validation-provider>
                <v-btn
                    block depressed
                    :disabled="invalid || loading"
                    :loading="loading"
                    height="50"
                    color="primary"
                    class="mt-9 rounded-sm white--text"
                    @click="changePw()">확인
                </v-btn>
              </form>
            </validation-observer>
          </v-container>
        </v-card-text>
      </v-card>
      <div class="bg-area">
        <div class="symbol_01">
          <v-img :src="require('@/assets/images/ess_bg01.svg')"></v-img>
        </div>
        <div class="symbol_02">
          <v-img :src="require('@/assets/images/ess_bg02.svg')"></v-img>
        </div>
      </div>
    </v-card>
  </v-app>
</template>

<script>
import CryptoJS from 'crypto-js';
import storage from '../common/storage';

export default {
  name: 'Login',
  components: {},
  data() {
    return {
      id: null,
      password: null,
      newPassword: null,
      checkNewPassword: null,
      loading: false,
      passwordShow: false,
      newPasswordShow: false,
      checkNewPasswordShow: false,
      // password: 'Password',
      enNumReg: /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,25}$/,
      enNumSpeReg: /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/,
      rules: {
        required: (value) => !!value || '필수 항목입니다.',
        min: (v) => v.length >= 6 || '적어도 6글자 이상이어야 합니다.',
        check: (v) => v === this.newPassword || '변경된 비밀번호를 확인해주세요',
        enNum: (v) => this.enNumReg.test(v) || '영문+숫자 조합 8자리 이상 25자리 이하여야합니다.',
        enNumS: (v) => this.enNumSpeReg.test(v) || '영문+숫자+특수문자 조합 8자리 이상 25자리 이하여야합니다.',
      },
    };
  },
  created() {
    this.$EventBus.$on('logout', this.onReceiveLogout);
    // 토큰 오류 경고창을 중첩하지 않도록
    this.$store.commit('setExpireDialog', false);
  },
  methods: {
    async changePw() {
      if (this.newPassword !== this.checkNewPassword) {
        alert('비밀번호를 다시 확인해주세요.');
        this.checkNewPassword = '';
        return;
      }
      this.loading = true;
      try {
        const result = await this.$axios.post('/auth/login-user', {
          userId: this.$route.query.userId,
          password: CryptoJS.SHA256(this.newPassword).toString(),
          prePassword: CryptoJS.SHA256(this.password).toString(),
        });
        if (result.status === 200) {
          alert('비밀번호가 변경되었습니다. 다시 로그인해주세요.');
          // storage.removeToken();
          // storage.removeRefreshToken();
          await this.$router.push({
            path: '/web/login',
          });
        }
      } catch (err) {
        // alert(err);
        await this.$dialog.warning({
          text: '기존 비밀번호가 일치하지 않습니다.',
          title: '비밀번호 변경 실패',
        });
      } finally {
        this.loading = false;
      }
    },
    go(path) {
      console.log(this.$route.path);
      if (this.$route.path !== path) {
        this.$router.push(path);
      }
    },
    submit() {
      console.log('submit');
    },
    onReceiveLogout() {
      storage.removeToken();
      storage.removeRefreshToken();
    },
  },
};
</script>
